import s from './user/state'
import * as a from './user/actions'
import * as m from './user/mutations'
import * as g from './user/getters'

console.log('actions', { ...a })

export const state = s
export const actions = { ...a }
export const mutations = { ...m }
export const getters = { ...g }
