<script>
import Layout from '@layouts/main'

export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },
  components: { Layout },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <h1 :class="$style.title">
    404
    <template v-if="resource">
      {{ resource }}
    </template>
    Not Found
  </h1>
</template>

<style lang="sass" module>
.title
  text-align: center
</style>
