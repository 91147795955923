<script>
// import con from '@connection'
import Snackbar from '@components/the-snackbar'
import Modal from '@modal/the-feedback'

export default {
  components: {  Snackbar, Modal },
  beforeCreate() {
    // con.post('/login', )
  },
}
</script>

<template>
  <div>
    <slot />

    <Snackbar />
    <Modal />
  </div>
</template>

<style lang="sass">
$body-font-family: "Sarabun" !important
$title-font: "Sarabun" !important

.border-right
  border-right: 1px dashed #E4E0E6

.title
  font-family: 'Sarabun' !important

.v-divider
  display: block !important
  border: solid !important
  border-width: thin 0 0 0 !important
  margin-top: 0px !important
  margin-bottom: 0px !important
  border-color: rgba(0,0,0,.12) !important

.v-btn
  text-transform: initial !important
  letter-spacing: initial !important

.v-tab
  text-transform: initial !important
</style>
