// import store from '@state/store'

export default [
  {
    path: '/',
    name: 'home',
    redirect: '/email',
  },
  {
    path: '/email',
    name: 'email',
    component: () => lazyLoadView(import('@views/login-email')),
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login')),
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: () => lazyLoadView(import('@views/cadastro')),
  },
  {
    path: '/cadastro_cliente',
    name: 'cadastro.cliente',
    component: () => lazyLoadView(import('@views/cadastro-cliente')),
  },
  {
    path: '/cadastro_conta',
    name: 'cadastro.conta',
    component: () => lazyLoadView(import('@views/cadastro-conta')), // profissional
    children: [
      {
        name: 'cadastro.conta.convite',
        path: ':email/:hash',
        component: () => lazyLoadView(import('@views/cadastro-conta')), // profissional
      },
    ],
  },
  {
    path: '/cadastro_empresa',
    name: 'cadastro.empresa',
    component: () => lazyLoadView(import('@views/cadastro-empresa')),
  },
  {
    path: '/esqueci_senha',
    name: 'recuperar.senha',
    component: () => lazyLoadView(import('@views/recuperar-senha')),
  },
  {
    path: '/recuperar_senha',
    name: 'recuperar.codigo',
    component: () => lazyLoadView(import('@views/recuperar-codigo')),
  },
  {
    path: '/profissional/recuperar_senha',
    name: 'recuperar.codigo_profissional',
    meta: {
      profissional: true,
    },
    component: () => lazyLoadView(import('@views/recuperar-codigo')),
  },
  {
    path: '/aguardar',
    name: 'aguardar',
    component: () => lazyLoadView(import('@views/aguardar-aprovacao')),
  },
  {
    path: '/sucesso',
    name: 'sucesso',
    component: () => lazyLoadView(import('@views/sucesso')),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    props: true,
    redirect: '/email',
  },
  {
    path: '*',
    redirect: '/email',
  },
]
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@views/_loading').default,
    delay: 400, // Delay antes de apresentar
    error: require('@views/_404').default, // Caso estoure o timeout
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
