/**
 * To call: store.dispatch('addSearchClient', {})
 * @param {Object} Client {name, id}
 */
const addSearchClient = ({ commit, state }, client) => {
  console.log('Novo cliente: ', client)
  commit('SET_CURRENT_CLIENT', client)
  commit('ADD_SEARCH_CLIENT', state.client)
}

const setCurrentUser = ({ commit }, user) => {
  commit('SET_CURRENT_USER', user)
}

const setEscritorios = ({ commit }, escritorios) => {
  commit('SET_ESCRITORIOS', escritorios)
}

export { addSearchClient, setCurrentUser, setEscritorios }
