export const textOnColor = (color) => {
  if (!color) {
    return '#030309'
  }
  const c = color.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  const isLight = luma / 255 > 0.669
  return isLight ? '#030309' : '#ebebeb'
}

export const luminance = (r, g, b) => {
  const a = [r, g, b].map(function (v) {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2])
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2])
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  return (brightest + 0.05) / (darkest + 0.05)
}

const componentToHex = (c) => {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  // return result ? {
  //   r: parseInt(result[1], 16),
  //   g: parseInt(result[2], 16),
  //   b: parseInt(result[3], 16)
  // } : null;
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ]
}

/**
 * Calcular a cor com melhor contraste para apresentar sobre uma cor especifica.
 * @param {Array} colors Lista de cores consideradas.
 * @param {String} base Cor utilizada como base.
 * @returns {String} Cor escolhida
 */
export const bestColorToContrast = (colors, base) => {
  const baseRGB = hexToRgb(base)
  const contrasts = colors.map((color) => {
    const colorRGB = hexToRgb(color)
    return contrast(colorRGB, baseRGB)
  })
  const index = contrasts.indexOf(Math.min(...contrasts))
  return colors[index]
}

/**
 *
 * @param {String} color HEX color. #FFFFFF
 * @param {Number} amt Effect to color
 */
export const brightnessColor = (color, amt) => {
  return pSBC(amt / 100, color, false, true)
}

export const pSBC = (p, c0, c1, l) => {
  let r
  let g
  let b
  let P
  let f
  let t
  let h
  let i = parseInt
  let m = Math.round
  let a = typeof c1 === 'string'
  if (
    typeof p !== 'number' ||
    p < -1 ||
    p > 1 ||
    typeof c0 !== 'string' ||
    (c0[0] !== 'r' && c0[0] !== '#') ||
    (c1 && !a)
  ) {
    return null
  }
  const pSBCr = (d) => {
    let n = d.length
    let x = {}
    if (n > 9) {
      d = d.split(',')
      const [r, g, b, a] = (d = d.split(','))
      n = d.length
      if (n < 3 || n > 4) {
        return null
      }
      x.r = i(r[3] === 'a' ? r.slice(5) : r.slice(4))
      x.g = i(g)
      x.b = i(b)
      x.a = a ? parseFloat(a) : -1
    } else {
      if (n === 8 || n === 6 || n < 4) {
        return null
      }
      if (n < 6) {
        d =
          '#' +
          d[1] +
          d[1] +
          d[2] +
          d[2] +
          d[3] +
          d[3] +
          (n > 4 ? d[4] + d[4] : '')
      }
      d = i(d.slice(1), 16)

      if (n === 9 || n === 5) {
        x.r = (d >> 24) & 255
        x.g = (d >> 16) & 255
        x.b = (d >> 8) & 255
        x.a = m((d & 255) / 0.255) / 1000
      } else {
        x.r = d >> 16
        x.g = (d >> 8) & 255
        x.b = d & 255
        x.a = -1
      }
    }
    return x
  }

  h = c0.length > 9
  h = a ? (c1.length > 9 ? true : c1 === 'c' ? !h : false) : h
  f = pSBCr(c0)
  P = p < 0
  t =
    c1 && c1 !== 'c'
      ? pSBCr(c1)
      : P
      ? { r: 0, g: 0, b: 0, a: -1 }
      : { r: 255, g: 255, b: 255, a: -1 }
  p = P ? p * -1 : p
  P = 1 - p

  if (!f || !t) {
    return null
  }
  if (l) {
    r = m(P * f.r + p * t.r)
    g = m(P * f.g + p * t.g)
    b = m(P * f.b + p * t.b)
  } else {
    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5)
  }

  a = f.a
  t = t.a
  f = a >= 0 || t >= 0
  a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0
  if (h) {
    return (
      'rgb' +
      (f ? 'a(' : '(') +
      r +
      ',' +
      g +
      ',' +
      b +
      (f ? ',' + m(a * 1000) / 1000 : '') +
      ')'
    )
  } else {
    return (
      '#' +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    )
  }
}

export const generatePalette = (
  color,
  { lightAmount = 50, darkAmount = -80, darkerCount = 5, lighterCount = 5 } = {}
) => {
  const gradient = new Gradient()
    .setColorGradient(
      brightnessColor(color, lightAmount),
      color,
      brightnessColor(color, darkAmount)
    )
    .setMidpoint(darkerCount + lighterCount)

  return gradient.getColors()
}

class GradientColor {
  constructor(startColor = '', endColor = '', minNum = 0, maxNum = 10) {
    this.setColorGradient = (colorStart, colorEnd) => {
      startColor = getHexColor(colorStart)
      endColor = getHexColor(colorEnd)
    }

    this.setMidpoint = (minNumber, maxNumber) => {
      minNum = minNumber
      maxNum = maxNumber
    }

    this.getColor = (numberValue) => {
      if (numberValue) {
        return (
          '#' +
          generateHex(
            numberValue,
            startColor.substring(0, 2),
            endColor.substring(0, 2)
          ) +
          generateHex(
            numberValue,
            startColor.substring(2, 4),
            endColor.substring(2, 4)
          ) +
          generateHex(
            numberValue,
            startColor.substring(4, 6),
            endColor.substring(4, 6)
          )
        )
      }
    }

    const generateHex = (number, start, end) => {
      if (number < minNum) {
        number = minNum
      } else if (number > maxNum) {
        number = maxNum
      }

      const midPoint = maxNum - minNum
      const startBase = parseInt(start, 16)
      const endBase = parseInt(end, 16)
      const average = (endBase - startBase) / midPoint
      const finalBase = Math.round(average * (number - minNum) + startBase)
      const balancedFinalBase =
        finalBase < 16 ? '0' + finalBase.toString(16) : finalBase.toString(16)
      return balancedFinalBase
    }

    const getHexColor = (color) => {
      return color.substring(color.length - 6, color.length)
    }
  }
}

class Gradient {
  constructor(
    colorGradients = '',
    maxNum = 10,
    colors = ['', ''],
    intervals = []
  ) {
    const setColorGradient = (gradientColors) => {
      if (gradientColors.length < 2) {
        throw new Error(
          `setColorGradient should have more than ${gradientColors.length} color`
        )
      } else {
        const increment = maxNum / (gradientColors.length - 1)
        const firstColorGradient = new GradientColor()
        const lower = 0
        const upper = 0 + increment
        firstColorGradient.setColorGradient(
          gradientColors[0],
          gradientColors[1]
        )
        firstColorGradient.setMidpoint(lower, upper)
        colorGradients = [firstColorGradient]
        intervals = [
          {
            lower,
            upper,
          },
        ]

        for (let i = 1; i < gradientColors.length - 1; i++) {
          const gradientColor = new GradientColor()
          const lower = 0 + increment * i
          const upper = 0 + increment * (i + 1)
          gradientColor.setColorGradient(
            gradientColors[i],
            gradientColors[i + 1]
          )
          gradientColor.setMidpoint(lower, upper)
          colorGradients[i] = gradientColor
          intervals[i] = {
            lower,
            upper,
          }
        }
        colors = gradientColors
      }
    }

    this.setColorGradient = (...gradientColors) => {
      setColorGradient(gradientColors)
      return this
    }

    this.getColors = () => {
      const gradientColorsArray = []
      for (let j = 0; j < intervals.length; j++) {
        const interval = intervals[j]
        const start = interval.lower === 0 ? 1 : Math.ceil(interval.lower)
        const end =
          interval.upper === maxNum
            ? interval.upper + 1
            : Math.ceil(interval.upper)
        for (let i = start; i < end; i++) {
          gradientColorsArray.push(colorGradients[j].getColor(i))
        }
      }
      return gradientColorsArray
    }

    this.getColor = (numberValue) => {
      if (isNaN(numberValue)) {
        throw new TypeError(`getColor should be a number`)
      } else if (numberValue <= 0) {
        throw new TypeError(`getColor should be greater than ${numberValue}`)
      } else {
        const toInsert = numberValue + 1
        const segment = (maxNum - 0) / colorGradients.length
        const index = Math.min(
          Math.floor((Math.max(numberValue, 0) - 0) / segment),
          colorGradients.length - 1
        )
        return colorGradients[index].getColor(toInsert)
      }
    }

    this.setMidpoint = (maxNumber) => {
      if (!isNaN(maxNumber) && maxNumber >= 0) {
        maxNum = maxNumber
        setColorGradient(colors)
      } else if (maxNumber <= 0) {
        throw new RangeError(`midPoint should be greater than ${maxNumber}`)
      } else {
        throw new RangeError('midPoint should be a number')
      }
      return this
    }
  }
}

// export const brightnessColor = (hex, percent = 15) => {
//   // strip the leading # if it's there
//   hex = hex.replace(/^\s*#|\s*$/g, '')

//   // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
//   if (hex.length == 3) {
//     hex = hex.replace(/(.)/g, '$1$1')
//   }

//   var r = parseInt(hex.substr(0, 2), 16)
//   var g = parseInt(hex.substr(2, 2), 16)
//   var b = parseInt(hex.substr(4, 2), 16)

//   return (
//     '#' +
//     (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
//     (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
//     (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
//   )
// }

// return lighter (+lum) or darker (-lum) color as a hex string
// pass original hex string and luminosity factor, e.g. -0.1 = 10% darker
// export function ColorLuminance(hex, lum) {
//   // validate hex string
//   hex = String(hex).replace(/[^0-9a-f]/gi, '')
//   if (hex.length < 6) {
//     hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
//   }
//   lum = lum || 0

//   // convert to decimal and change luminosity
//   var rgb = '#'
//   var c
//   var i
//   for (i = 0; i < 3; i++) {
//     c = parseInt(hex.substr(i * 2, 2), 16)
//     c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
//     rgb += ('00' + c).substr(c.length)
//   }

//   return rgb
// }

// function getContrastYIQ(hexcolor) {
//   hexcolor = hexcolor.replace('#', '')
//   var r = parseInt(hexcolor.substr(0, 2), 16)
//   var g = parseInt(hexcolor.substr(2, 2), 16)
//   var b = parseInt(hexcolor.substr(4, 2), 16)
//   var yiq = (r * 299 + g * 587 + b * 114) / 1000
//   return yiq >= 128 ? 'black' : 'white'
// }

// function shadeColor(color, percent) {
//   var R = parseInt(color.substring(1, 3), 16)
//   var G = parseInt(color.substring(3, 5), 16)
//   var B = parseInt(color.substring(5, 7), 16)

//   R = parseInt((R * (100 + percent)) / 100)
//   G = parseInt((G * (100 + percent)) / 100)
//   B = parseInt((B * (100 + percent)) / 100)

//   R = R < 255 ? R : 255
//   G = G < 255 ? G : 255
//   B = B < 255 ? B : 255

//   var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
//   var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
//   var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

//   return '#' + RR + GG + BB
// }
