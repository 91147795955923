import Vue from 'vue'
import Vuetify from 'vuetify'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: false,
      light: {
        primary: '#56AF79',
        // secondary: '#C7BBC9',
        secondary: '#8E8E8E',
        accent: '#82B1FF',
        error: '#EB5757',
        info: '#2196F3',
        success: '#6FCF97',
        warning: '#FF6433', // '#FFD500',
        danger: '#EB5757',
        purple: '#7000FF',
      },
    },
  },
}

export default new Vuetify(opts)
