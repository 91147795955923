import { paleta } from '@constants/Color'
import Vue from 'vue'
import store from '@state/store'

const SET_CLASS_COLORS = (state, payload) => {
  let aux = {}
  payload.forEach((v, i) => {
    aux[v] = paleta[i % paleta.length]
  })
  state.classColors = aux
}

const SET_BANKS_COLORS = (state, payload) => {
  let aux = {}
  payload.forEach((v, i) => {
    aux[v] = paleta.reverse()[i % paleta.length]
  })
  state.banksColors = aux
}

const UPDATE_COLOR_MODE = (state, payload) => {
  if (payload.mode && payload.colors) {
    state.colors[payload.mode] = {
      ...state.colors[payload.mode],
      ...payload.colors,
    }

    Object.entries(state.colors[payload.mode]).forEach((c) => {
      document.documentElement.style.setProperty(`--${c[0]}`, c[1])
    })
  }
}

const SET_ESCRITORIO_BASE = (state, payload) => {
  if (payload && payload.id_escritorio) {
    state.escritorio = payload
  }
}

const SET_COLORS = (state, { colors, vuetify }) => {
  const payload = colors
  if (payload) {
    state.colors['light_mode'] = {
      ...state.colors['light_mode'],
      ...payload,
    }

    if (Vue.$vuetify || vuetify) {
      Object.entries(payload).forEach((c) => {
        ;(Vue.$vuetify || vuetify.framework).theme.themes.light[c[0]] = c[1]
      })
    } else {
      document.documentElement.style.setProperty(
        '--v-primary-base',
        payload.primary
      )
      document.documentElement.style.setProperty(
        '--v-accent-base',
        payload.accent
      )
    }
  }
}

const LOAD_CONFIG_LOCAL = (state) => {
  const user = store.getters['user/currentUser'] || {}
  const config = window.localStorage.getItem(`6CFgc${user.id_interno}10239c`)
  if (config) {
    state.config_local = {
      ...state.config_local,
      ...JSON.parse(config),
    }
  }
}

const SET_CONFIG_LOCAL = (state, payload) => {
  const user = store.getters['user/currentUser'] || {}
  if (payload) {
    state.config_local = {
      ...state.config_local,
      ...payload,
    }

    window.localStorage.setItem(
      `6CFgc${user.id_interno}10239c`,
      JSON.stringify(state.config_local)
    )
  }
}

const SET_LOGO = (state, payload) => {
  if (payload) {
    state.logo = payload
  }
}

const SET_FAVICON = (state, payload) => {
  if (payload) {
    state.favicon = payload
  }
}

const SET_BG = (state, payload) => {
  if (payload) {
    state.backgroundImage = payload
  }
}

export {
  SET_CLASS_COLORS,
  SET_BANKS_COLORS,
  UPDATE_COLOR_MODE,
  SET_ESCRITORIO_BASE,
  SET_COLORS,
  LOAD_CONFIG_LOCAL,
  SET_CONFIG_LOCAL,
  SET_LOGO,
  SET_FAVICON,
  SET_BG,
}
