<script>
import store from '@state/store'
import { mapState } from 'vuex'

// TODO: promisse para retornar botão clicado

export default {
  data() {
    return {
      show: false,
    }
  },
  computed: {
    // ...mapState('feedback', ['modal']),
    modal() {
      return store.getters['feedback/modal']
    },
  },
  watch: {
    modal(newValue) {
      this.show = newValue.show
    },
    show(newValue) {
      if (!newValue) {
        store.dispatch('feedback/closeModal')
      }
    },
  },
}
</script>

<template>
  <div class="v-application">
    <v-dialog v-model="show" max-width="500px">
      <v-card>
        <v-icon
          :class="$style.close_button"
          :style="{ 'z-index': 4 }"
          color="--red"
          @click="show = !show"
        >
          mdi-close
        </v-icon>
        <v-card-title>
          <h1 class="title"> {{ modal.title }} </h1>
        </v-card-title>
        <v-card-text>
          <span> {{ modal.text }} </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <BaseButton primary @click="show = false">
            Ok
          </BaseButton>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="sass" module>
.close_button
  elevation: 1
  position: absolute
  right: 20px
  top: 20px
  color: var(--red) !important
</style>
