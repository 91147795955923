import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { baseURL } from '@constants/URL'

const productionMode = process.env.NODE_ENV === 'production'
const url = productionMode ? 'prod' : 'dev'
// const url = 'local'

console.log('axios:baseURL:', baseURL[url])

const api = axios.create({
  baseURL: productionMode ? '' : baseURL[url],
  // withCredentials: true,
  // headers: {
  //   ...(productionMode && {
  //     'Content-Type': 'multipart/form-data',
  //     'Access-Control-Allow-Origin': '*',
  //   }),
  // },
  // crossDomain: true,
})

console.log(productionMode, '## Utilizando base: ', baseURL[url])

// if (productionMode) {
//   api.defaults.headers.post['Content-Type'] = 'multipart/form-data' // 'application/x-www-form-urlencoded'
//   api.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// }
api.interceptors.request.use(undefined, (config) => {
  console.log('erro requisição')
  if (config.response.status === 401) {
    console.log('REDIRECIONAR PARA LOGIN')
  }
  return Promise.reject(config)
})

Vue.use(VueAxios, axios)

export default api
