export default {
  snackbar: {
    text: '',
    button: true,
    timeout: 4000,
    color: '',
    show: false,
  },
  modal: {
    type: '',
    text: '',
    title: 'Aviso',
    buttons: [],
    closable: true,
    persistent: false,
  },
}
