const getSearchedClients = (state) => {
  return state.clients.filter((v) => {
    return v.id !== state.client.id
  })
}

const currentClient = (state) => {
  return state.client
}

const currentUser = (state) => {
  return state.user || {}
}

const escritorios = (state) => {
  return state.escritorios || []
}

export { getSearchedClients, currentClient, currentUser, escritorios }
