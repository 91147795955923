<script>
import Layout from '@layouts/main'
import store from '@state/store'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title
        ? `${title} | ${
            window.PARAMETROS ? window.PARAMETROS.escritorio.nome || 'AAWZ' : ''
          }`
        : window.PARAMETROS
        ? window.PARAMETROS.escritorio.nome || 'AAWZ'
        : ''
    },
  },
  components: { Layout },
  computed: {
    bg() {
      return store.getters['parameters/bg']
    },
  },
}
</script>

<template>
  <v-app id="app" style="background-color: var(--bg-login)" dark>
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <Layout>
      <div fill-height fill-width style="justify-content: center" class="row">
        <div class="col-8 py-0 hidden-sm-and-down">
          <v-img
            :src="bg"
            height="100vh"
            style="margin: auto"
            position="left center"
          />
        </div>
        <div sm="12" md="4" class="col-md-4 col-sm-12 py-0">
          <RouterView :key="$route.fullPath" />
        </div>
      </div>
    </Layout>
  </v-app>
</template>

<style lang="sass">
@import '~normalize.css/normalize.css'
@import '~nprogress/nprogress.css'
@import './design/index.sass'
// @import './design/style'

.subhead
  margin-bottom: 8px
  font-size: rem(20px)
  font-weight: 300
  line-height: 1.3
  color: var(--text-on-bg)
  font-weight: 500
  text-transform: uppercase

.subtitle
  margin-bottom: 31px
  font-size: 0.75rem
  font-weight: bold
  color: var(--text-on-bg)

#nprogress .bar
  background: var(--primary)

#nprogress .spinner-icon
  border-top-color: var(--primary)
  border-left-color: var(--primary)
</style>

<!-- This should generally be the only global CSS in the app.
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/design.
@import '@design';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $color-body-bg;
}
#app {
  @extend %typography-small;
}

// ===
// Base element styles
// ===

a,
a:visited {
  color: $color-link-text;
}

h1 {
  @extend %typography-xxlarge;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;
}

h4 {
  @extend %typography-medium;
}

h5,
h6 {
  @extend %typography-small;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: $color-link-text;
}
</style>-->
