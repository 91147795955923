export default {
  clients: [],
  client: null,
  user: {
    nome: '',
    id_interno: -1,
    escritorio: {
      nome: PARAMETROS.escritorio.nome,
      id_interno: PARAMETROS.escritorio.id_escritorio,
    },
    tipo_usuario: '', // GESTOR, ASSESSOR, PLANEJADOR, CLIENTE
    IS_GESTOR: false,
    IS_ASSESSOR: false,
    IS_PLANEJADOR: false,
    IS_CLIENTE: true,
  },
  escritorios: [
    {
      nome: 'AAWZ',
      id: 3,
    },
  ],
}
