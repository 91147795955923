<script>
import store from '@state/store'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      show: false,
    }
  },
  watch: {
    snackbar(newValue) {
      this.show = newValue.show
    },
    show(newValue) {
      if (!newValue) {
        store.dispatch('feedback/closeSnack')
      }
    },
  },
  computed: {
    // snackbar() {
    //   return store.getters['feedback/snack']
    // },
    ...mapState('feedback', ['snackbar']),
  },
}
</script>

<template>
  <div class="v-application">
    <v-snackbar
      v-model="show"
      :timeout="snackbar.timeout"
      right
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
        v-if="snackbar.button"
        :color="snackbar.color ? '' : 'blue darken-1'"
        text
        @click="show = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<style lang="sass" module></style>
