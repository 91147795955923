import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-application"},[_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VIcon,{class:_vm.$style.close_button,style:({ 'z-index': 4 }),attrs:{"color":"--red"},on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" mdi-close ")]),_c(VCardTitle,[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.modal.title)+" ")])]),_c(VCardText,[_c('span',[_vm._v(" "+_vm._s(_vm.modal.text)+" ")])]),_c(VCardActions,[_c(VSpacer),_c('BaseButton',{attrs:{"primary":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Ok ")]),_c(VSpacer)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }