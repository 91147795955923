/**
 * To call: store.dispatch('addExample', '')
 */
const setClassColors = ({ commit }, classes) => {
  commit('SET_CLASS_COLORS', classes)
}

const setBanksColors = ({ commit }, banks) => {
  commit('SET_BANKS_COLORS', banks)
}

const updateColors = ({ commit }, data) => {
  // {mode, colors}
  commit('UPDATE_COLOR_MODE', data)
}

const setEscritorioBase = ({ commit }, escritorio) => {
  commit('SET_ESCRITORIO_BASE', escritorio)
}

const setColors = ({ commit }, { colors, vuetify }) => {
  commit('SET_COLORS', { colors, vuetify })
}

const loadConfigLocal = ({ commit }) => {
  commit('LOAD_CONFIG_LOCAL')
}

const saveConfigLocal = ({ commit }, payload) => {
  commit('SET_CONFIG_LOCAL', payload)
}

const setLogo = ({ commit }, logo) => {
  commit('SET_LOGO', logo)
}

const setFavicon = ({ commit }, favicon) => {
  commit('SET_FAVICON', favicon)
}

const setBG = ({ commit }, bg) => {
  commit('SET_BG', bg)
}

export {
  setClassColors,
  setBanksColors,
  updateColors,
  setEscritorioBase,
  setColors,
  loadConfigLocal,
  saveConfigLocal,
  setLogo,
  setFavicon,
  setBG,
}
