import { corretoras } from '@constants/Text'
import moment from 'moment'

/**
 * Aplicar mascara monetaria para valor numérico.
 * Arredonda as casas decimais para a precisão.
 * @param {int} n Numero para formatar
 * @param {int} c Precisão das casas decimais
 * @param {String} d Separador decimal
 * @param {String} t Separador milhar
 */
export const formatNumber = (n, c = 2, d = ',', t = '.') => {
  c = isNaN((c = Math.abs(c))) ? 2 : c
  d = d === undefined ? '.' : d
  t = t === undefined ? ',' : t
  let s = n < 0 ? '-' : ''
  let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))))
  let j = i.length > 3 ? i.length % 3 : 0
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  )
}

/**
 * Converter número de string da mascara BR para valor tipo numério js.
 * @param {String} num Número a ser formatado. Esperada mascara BR de número.
 * @returns {Number} Número extraido.
 */
export const getNumber = (num) => {
  if (num && typeof num === 'string') {
    return (
      Number(
        num
          .replace(/[^0-9,.-]/g, '')
          .split('.')
          .join('')
          .replace(',', '.')
      ) || 0
    )
  }
  return num
}

/**
 * Capitalizar texto para deixar somente a primeira letraa de cada palavra em caixa alta
 * @param {String} phrase Frase para capitalizar primeira letra de cada palavra
 */
export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/**
 * Adiciona a quantidade de dias passados.
 * @param {Date} data Data para ser considerada como base. Formato deve ser válido para o new Date().
 * @param {Number} days Número de dias à adicionar. Negativo ou positivo.
 * @returns {Date} Data calculada - Incluido os dias passados no parametro.
 */
export const addDays = (data, days) => {
  const date = new Date(data)
  date.setDate(date.getDate() + days)
  return date
}

/**
 * Realiza o cálculo do atraso da data passada em relação a data atual.
 * @param {Date} data_att Data no formato DD/MM/YYYY HH:mm.
 * @returns {Number} Resultado da comparação:
 *          1 - hoje
 *          2 - Até 3 dias desatualizado
 *          3 - Até 7 dias desatualizado
 *          4 - > 7 dias desatualizado
 */
export const statusDesatualizado = (data_att) => {
  console.log('statusDesatualizado', data_att)
  if (typeof data_att === 'string') {
    data_att = moment(data_att, 'DD/MM/YYYY HH:mm')._d
  }
  if (data_att) {
    if (!data_att.getDate) {
      console.log('1')
      return 1
    }
  } else {
    console.log('1')
    return 1
  }
  const today = new Date()
  if (data_att.getDate() === today.getDate()) {
    console.log('1')
    return 1
  }
  if (data_att >= addDays(today, -3)) {
    console.log('2')
    return 2
  }
  if (data_att >= addDays(today, -7)) {
    console.log('3')
    return 3
  }
  console.log('4')
  return 4
}

/**
 * Formatar para percentual conforme nosso padrão.
 * @param {Number} num Percentual em valor decimal (55.5).
 * @param {Number} precision Quantidade de casas decimais.
 * @return {String} Percentual formatado (55,5%);
 */
export const toPercent = (num, precision = 2) => {
  if (typeof num === 'number' || Number(num)) {
    return `${formatNumber(num, precision)}%`
  }
  return num
}

/**
 * Aplica máscara monetária de reais.
 * @param {Number} num Valor em reais.
 * @returns {String} Valor formatado (R$ 222,22).
 */
export const toMoney = (num) => {
  if (typeof num === 'number' || Number(num)) {
    return `R$ ${formatNumber(num)}`
  }
  return num
}

/**
 * Verifica se o parametro recebido é um cnpj valido
 * @param {String} cnpj String para verificar
 * @returns {Boolean} Cnpj valido
 */
export const validateCnpj = (cnpj) => {
  const verify = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
  return verify.test(cnpj)
}

/**
 * Considera se o objeto é um JSON, retorno o próprio objeto.
 * @param {String/Object} j Objeto a verificar.
 * @returns {Object} Objeto recebido.
 */
export const possivelJSON = (j) => (typeof j === 'string' ? JSON.parse(j) : j)

/**
 * Recebe o endereço de e-mail e verifica o formato dele.
 * @param {String} email Endereço de e-mail.
 * @returns {Boolean} Endereço válido
 */
export const checkEmail = (email) => {
  // const chars = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const chars = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return chars.test(email)
}

/**
 * Formata o telefone para o padrão BR, considerando a possibilidade de 9 digitos.
 * @param {String} phone Telefone a ser formatado.
 * @returns {String} Telefone formatado.
 */
export const formatPhone = (phone) => {
  phone = phone.replace(/\D/g, '')
  phone = phone.replace(/(\d{0})(\d)/, '$1($2')
  phone = phone.replace(/(\d{2})(\d)/, '$1) $2')
  if (phone.length === 14) {
    phone = phone.replace(/(\d{5})(\d)/, '$1-$2')
  } else {
    phone = phone.replace(/(\d{4})(\d)/, '$1-$2')
  }
  return phone
}

/**
 * Formata o valor do CPF.
 * @param {String} cpf CPF a ser formatado.
 * @returns {String} CPF formatado.
 */
export const formatCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, '')
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2', /(\d{3})(\d)/, '$1.$2')
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  return cpf
}

/**
 * Formata o valor do CNPJ.
 * @param {String} cnpj CNPJ a ser formatado.
 * @returns {String} CNPJ formatado.
 */
export const formatCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, '')
  // cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2')
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2')
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2')
  return cnpj
}

/**
 * Formatar o campo de documento de acordo com tamanho.
 * @param {String} text CPF ou CNPJ a ser formatado.
 * @returns {String} Campo de documento formatado de acordo com o tipo
 */
export const formatCPFeCNPJ = (text) => {
  if (text.length <= 15) {
    return formatCPF(text)
  }
  return formatCNPJ(text)
}

/**
 * Formata o valor do CEP.
 * @param {String} cep CEP a ser formatado.
 * @returns {String} CEP formatado.
 */
export const formatCEP = (cep) => {
  cep = cep.replace(/^(\d{5})(\d)/, '$1-$2')
  return cep
}

/**
 * Retornar apenas os numeros de um texto.
 * @param {String} v String a ser formatada.
 */
export const extractNumber = (v) => {
  if (v) {
    return v.replace(/[^0-9]/g, '')
  }
  return v
}

/**
 * Formatar data para o formato válido para a Wirecard.
 * @param {Date} data Data no formato válido para o moments.
 * @returns {String} Data formatada (YYYY-MM-DD).
 */
export const dataWirecard = (data) =>
  moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')

/**
 * Formata um valor para data
 * @param {String} data Valor que deseja transformar em data
 * @returns {String} Retorna o valor em formato como data
 */
export const toDate = (data) => {
  data = data.replace(/\D/g, '')
  data = data.replace(/(\d{2})(\d)/, '$1/$2')
  data = data.replace(/(\d{2})(\d)/, '$1/$2')
  data = data.replace(/(\d{2})(\d{2})$/, '$1$2')

  return data
}

/**
 * Validar data.
 * @param {String} d Data a ser validada.
 * @param {String} mask Mascara a ser utilizada. DD/MM/YYYY
 */
export const validarData = (d, mask = 'DD/MM/YYYY') => {
  if (d) {
    if (d.length === 10) {
      const data = moment(d, mask).format(mask) // TODO mascara de data constant
      return data === 'Invalid date' ? false : data
    }
  }
  return false
}

/**
 * Comparando duas datas simples.
 * 	 1 - Primeira data maior.
 *   0 - Datas iguais.
 * 	-1 - Primeira data menor.
 * @param {String} d1 Primeira data para comparação (DD/MM/YYYY).
 * @param {String} d2 Segunda data para comparação (DD/MM/YYYY).
 */
export const compararDatas = (d1, d2 = moment().format('DD/MM/YYYY')) => {
  if (d2 && typeof d2 === 'string') {
    d2 = moment(d2, 'DD/MM/YYYY')
  }
  if (d1) {
    let d1F = moment(d1, 'DD/MM/YYYY')
    return Math.sign(d1F - d2)
  }
  return null
}

/**
 * Formatar data com o padrão do sistema.
 * @param {Date|String} d Campo da data a ser formatado.
 * @param {String} tipo Qual tipo de data esperado na resposta (Completo(DD/MM/YYYY HH:mm)|Curto(DD/MM/YYYY)|Abreviado(DD/MM/YY)).
 * @param {String} mask_in Mascara da data a ser formatada.
 */
export const formatarData = (d, tipo = 'Completo', mask_in = 'DD/MM/YYYY') => {
  if (tipo === 'Completo') {
    return formatDate(d, mask_in, 'DD/MM/YYYY HH:mm')
  } else if (tipo === 'Curto') {
    return formatDate(d, mask_in, 'DD/MM/YYYY')
  } else if (tipo === 'Abreviado') {
    return formatDate(d, mask_in, 'DD/MM/YY')
  } else {
    return formatDate(d, mask_in, 'DD/MM/YYYY')
  }
}

/**
 * Formatar data de acordo com a mascara sendo passada no parametro.
 * @param {Date|String} d Valor da data a ser formatado.
 * @param {String} mask_in Formato da data a ser formatada caso string.
 * @param {String} mask_out Formato da data de retorno.
 */
export const formatDate = (
  d,
  mask_in = 'DD/MM/YYYY',
  mask_out = 'DD/MM/YYYY'
) => {
  if (d) {
    let data = '-'
    if (typeof d === 'string' && d.length === 10) {
      data = moment(d, mask_in).format(mask_out)
    } else {
      data = moment(d).format(mask_out)
    }
    return data === 'Invalid date' ? '-' : data
  }
  return '-'
}

export const getCorretora = (v) => {
  return corretoras[v] || v
}

/**
 * Remove os acentos do texto.
 * @param {String} texto Texto a ser considerado.
 * @returns {String} Texto tratado.
 */
export const removeAccents = (texto) => {
  let string = texto

  const acentos = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  }

  for (const letras in acentos) {
    const filtrar = acentos[letras]
    string = string.replace(filtrar, letras)
  }

  return string
}
