
import s from './feedback/state'
import * as a from './feedback/actions'
import * as m from './feedback/mutations'
import * as g from './feedback/getters'

export const state = s
export const actions = {...a}
export const mutations = {...m}
export const getters = {...g}
