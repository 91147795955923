<script>
import store from '@state/store'
export default {
  computed: {
    logo() {
      return store.getters['parameters/logo']
    },
  },
}
</script>

<template>
  <v-img :src="logo" max-height="100" max-width="250" contain />
</template>

<style lang="sass" module></style>
