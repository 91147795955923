<script>
export default {
  page: {
    title: 'Carregando...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
}
</script>

<template>
  <Transition appear>
    <!-- <BaseIcon :class="$style.loadingIcon" name="sync" spin /> -->
  </Transition>
</template>

<style lang="sass" module>
.loadingIcon
  color: #1966b1
</style>
