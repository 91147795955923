export const corretoras = {
  xp: 'XP',
  bb: 'Banco do Brasil',
  itau: 'Itaú',
  santander: 'Santander',
  bradesco: 'Bradesco',
  btg: 'BTG Pactual',
  safra: 'Safra',
  genial: 'Genial',
}

export const SEM_CADASTRO = ['92']
