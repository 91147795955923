/* global WHITELABEL */
/* global PARAMETROS */
/* eslint import/order: "off" */
/* eslint new-cap: "off" */

import Vue from 'vue'
import App from './app'
import router from '@router'
import store from '@state/store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
// import 'vuetify/src/styles/styles.sass'
import 'vuetify/dist/vuetify.min.css'
import '@components/_globals'
import './design/main.sass'
import { textOnColor, brightnessColor } from '@utils/Color'

import '@mdi/font/css/materialdesignicons.css'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.mixin({
  methods: {
    $currentUser() {
      return store.getters['user/currentUser']
    },
    $currentClient() {
      return store.getters['user/currentClient']
    },
    $showSnack(snack) {
      store.dispatch('feedback/showSnack', snack)
    },
    $hideSnack(snack) {
      store.dispatch('feedback/closeSnack', snack)
    },
    $showModal(modal) {
      store.dispatch('feedback/showModal', modal)
    },
    $hideModal(modal) {
      store.dispatch('feedback/closeModal', modal)
    },
  },
})

// TODO: Arquivo para init
;(function () {
  /**
   * Decimal adjustment of a number.
   *
   * @param    {String}    type    The type of adjustment.
   * @param    {Number}    value    The number.
   * @param    {Integer}    exp        The exponent (the 10 logarithm of the adjustment base).
   * @returns    {Number}            The adjusted value.
   */
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split('e')
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)))
    // Shift back
    value = value.toString().split('e')
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
  }

  // Decimal round
  if (!Math.round10) {
    Math.round10 = function (value, exp) {
      return decimalAdjust('round', value, exp)
    }
  }
  // Decimal floor
  if (!Math.floor10) {
    Math.floor10 = function (value, exp) {
      return decimalAdjust('floor', value, exp)
    }
  }
  // Decimal ceil
  if (!Math.ceil10) {
    Math.ceil10 = function (value, exp) {
      return decimalAdjust('ceil', value, exp)
    }
  }
})()

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

if (typeof window.PARAMETROS !== 'undefined' && window.PARAMETROS) {
  if (typeof PARAMETROS.officeColors === 'string') {
    try {
      PARAMETROS.officeColors = JSON.parse(PARAMETROS.officeColors)
    } catch (err) {
      console.error(err)
    } finally {
      if (!PARAMETROS.officeColors) {
        PARAMETROS.officeColors = {}
      }
    }
  }
  const textOnPrimary = textOnColor(PARAMETROS.colors.primary)
  PARAMETROS.colors['text-on-primary'] = textOnPrimary || '#FFF'
  const textOnSecondary = textOnColor(PARAMETROS.colors.second)
  PARAMETROS.colors['text-on-second'] = textOnSecondary || '#030309'
  if (
    PARAMETROS.officeColors['bg_navbar_header'] &&
    PARAMETROS.colors.primary &&
    PARAMETROS.officeColors['bg_navbar_header'].toLowerCase() ===
      PARAMETROS.colors.primary.toLowerCase()
  ) {
    PARAMETROS.officeColors['bg_navbar_header'] =
      PARAMETROS.officeColors['bg_navbar']
  }
  const textOnBgHeaderNavbar = textOnColor(
    PARAMETROS.officeColors['bg_navbar_header'] || '#f1eff2'
  )
  PARAMETROS.colors['text-on-navbar-header'] = textOnBgHeaderNavbar || '#030309'
  PARAMETROS.colors['primary-lighter'] = brightnessColor(
    PARAMETROS.colors.primary,
    80
  )
  PARAMETROS.colors['bg-navbar-header'] =
    PARAMETROS.officeColors['bg_navbar_header'] || '#f1eff2'
  PARAMETROS.colors['bg-navbar'] =
    PARAMETROS.officeColors['bg_navbar'] || '#030309'
  PARAMETROS.colors['text-navbar-active'] =
    PARAMETROS.officeColors['text_navbar_active'] || PARAMETROS.colors.primary
  PARAMETROS.colors['bg-login'] =
    PARAMETROS.officeColors['bg_login'] || '#f1eff2'
  PARAMETROS.colors['text-on-bg'] = textOnColor(PARAMETROS.colors['bg-login'])
  store.dispatch('parameters/setEscritorioBase', PARAMETROS.escritorio)
  store.dispatch('parameters/setColors', { colors: PARAMETROS.colors, vuetify })
  store.dispatch('parameters/updateColors', {
    mode: 'light_mode',
    colors: PARAMETROS.colors,
  })
  store.dispatch('parameters/setLogo', PARAMETROS.logo)
  store.dispatch('parameters/setFavicon', PARAMETROS.favicon)
  store.dispatch('parameters/setBG', PARAMETROS.backgroundImage)
}

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    Vue,
    dsn: 'https://d3091442b9354709a0b78abf934e3038@o437379.ingest.sentry.io/5602235',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.NODE_ENV + ' | ' + window.location.host,
  })
}

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
