/**
 * To call: store.dispatch('addExample', '')
 */
const showSnack = ({ commit }, snack) => {
  const aux = {
    text: '',
    action: () => {},
    timeout: 4000,
  }
  commit('SHOW_SNACKBAR', { ...aux, ...snack })
}

const closeSnack = ({ commit }) => {
  commit('CLOSE_SNACKBAR')
}

const showModal = ({ commit }, modal) => {
  const aux = {
    type: '',
    text: '',
    title: 'Aviso',
    buttons: [],
    closable: true,
    persistent: false,
  }
  commit('SHOW_MODAL', { ...aux, ...modal })
}

const closeModal = ({ commit }) => {
  commit('CLOSE_MODAL')
}

export { showSnack, closeSnack, showModal, closeModal }
