export const paleta = [
  '#374459',
  '#2E86AB',
  '#6E1100',
  '#E75735',
  '#986F4B',
  '#2FB89F',
  '#645E9D',
  '#7FB97F',
  '#F6E89F',
  '#644D40',
  '#2D6727',
  '#FF8F00',
  '#6C757D',
  '#65CB3B',
  '#FFBB00',
  '#AAABE2',
  '#F29741',
]

export const colors = [
  '#60be7b',
  '#34804c',
  '#296ab4',
  '#124a88',
  '#807f7f',
  '#545454',
  '#010101',
  '#6f3996',
]

export const segments = {
  varejo: '#5B9BD5',
  altaRenda: '#5EBE7B',
  private: '#7F7F7F',
}

export const light_mode = {
  white: '#FFFFFF',
  black: '#030309',
  green: '#56AF79',
  'green-light': '#C8E6C9',
  red: '#FF5252',
  redDarker: '#D50000',
  'red-light': '#FFCDD2',
  yellow: '#FFD500',
  'yellow-light': '#FFF9C4',
  blue: '#3242B9',
  'gray-1': '#F0F1F5',
  'gray-2': '#F1EFF2',
  'gray-3': '#E4E0E6',
  'gray-4': '#C7BBC9',
  'gray-5': '#A79CAC',
  'gray-6': '#87798D',
  'gray-7': '#38303B',
  'gray-8': '#2D212F',
  primary: '#56AF79',
  // secondary: '#C7BBC9',
  secondary: '#8e8e8e',
  success: '#6FCF97',
  danger: '#EB5757',
  light: '#F0F1F5',
  dark: '#2D212F',
  warning: '#FFD500',
}

export default {
  segments,
  paleta,
  light_mode,
  colors,
}
