import { getCorretora } from '@utils/Format'

const classColor = (state) => (classe, index) => {
  return state.classColors[classe] || paleta[index] || '#6C757D'
}

const bankColor = (state) => (classe, index) => {
  return (
    state.banksColors[classe] ||
    state.banksColors[getCorretora(classe)] ||
    state.colors.paleta[index] ||
    '#6C757D'
  )
}

const colors = (state) => {
  return state.colors[state.colors.mode]
}

const paleta = (state) => {
  return state.colors.paleta
}

const config_local = (state) => {
  return state.config_local || {}
}

const logo = (state) => {
  return state.logo
}

const favicon = (state) => {
  return state.favicon
}

const bg = (state) => {
  return state.backgroundImage
}

const escritorio = (state) => {
  return state.escritorio || {}
}

export {
  classColor,
  bankColor,
  colors,
  paleta,
  logo,
  favicon,
  bg,
  escritorio,
  config_local,
}
