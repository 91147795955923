<script>
export default {
  props: {
    bus: {
      type: Object,
      default: null,
    },
    buttonTitle: {
      type: String,
      default: 'Abrir modal',
    },
    maxWidth: {
      type: String,
      default: '600px',
    },
    height: {
      type: String,
      default: '400px',
    },
    contentClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    hasSlotButton() {
      console.log(`hasSlotButton button`, !!this.$slots['button'])
      return !!this.$slots['button'] || !!this.$scopedSlots['button']
    },
    hasSlotActions() {
      console.log(`hasSlotButton actions`, !!this.$slots['actions'])
      return !!this.$slots['actions'] || !!this.$scopedSlots['actions']
    },
  },
  created() {
    if (this.bus) {
      this.bus.$on('dialog', (v) => {
        this.dialog = v
      })
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
  },
}
</script>

<template>
  <div data-app>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="maxWidth"
      :height="height"
      :content-class="contentClass"
    >
      <template v-if="hasSlotButton" v-slot:activator="{ on }">
        <BaseButton none v-on="on">
          <slot name="button" :open="on" />
        </BaseButton>
      </template>
      <v-card
        class="v-application"
        :style="{
          'min-height': '50px',
        }"
      >
        <v-icon
          :class="$style.close_button"
          :style="{ 'z-index': 4 }"
          color="--red"
          @click="dialog = !dialog"
        >
          mdi-close
        </v-icon>
        <slot />
        <v-card-actions v-if="hasSlotActions">
          <slot name="actions" :close="close" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="sass" module>
.close_button
  elevation: 1
  position: absolute
  right: 20px
  top: 20px
  color: var(--red) !important
</style>
