const ADD_SEARCH_CLIENT = (state, payload) => {
  if (payload) {
    let lista = state.clients
    lista = lista.filter((v) => {
      return v.id !== payload.id
    })
    state.clients = [payload, ...lista]
  }
}

const SET_CURRENT_CLIENT = (state, payload) => {
  if (payload) {
    state.client = payload
  }
}

const SET_CURRENT_USER = (state, payload) => {
  if (payload) {
    // const perm = require('@constants/Permissoes').default
    const Vue = require('vue').default
    state.user = { ...payload } // ...perm[payload.tipo_usuario]
    Vue.prototype.$user = state.user
    console.info('Usuário: ', state.user)
    if (state.user.IS_CLIENTE) {
      state.client = {
        nome_cliente: payload.nome,
        id: payload.id_interno,
      }
    }
  } else {
    state.user = null
  }
}

const SET_ESCRITORIOS = (state, payload) => {
  if (payload) {
    state.escritorios = payload
  }
}

export {
  ADD_SEARCH_CLIENT,
  SET_CURRENT_CLIENT,
  SET_CURRENT_USER,
  SET_ESCRITORIOS,
}
