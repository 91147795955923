import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-app":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":_vm.maxWidth,"height":_vm.height,"content-class":_vm.contentClass},scopedSlots:_vm._u([(_vm.hasSlotButton)?{key:"activator",fn:function({ on }){return [_c('BaseButton',_vm._g({attrs:{"none":""}},on),[_vm._t("button",null,{"open":on})],2)]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"v-application",style:({
        'min-height': '50px',
      })},[_c(VIcon,{class:_vm.$style.close_button,style:({ 'z-index': 4 }),attrs:{"color":"--red"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" mdi-close ")]),_vm._t("default"),(_vm.hasSlotActions)?_c(VCardActions,[_vm._t("actions",null,{"close":_vm.close})],2):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }