// TODO: Rota para coletar informações do usuario

// Configurações
const baseURL = {
  local: 'http://localhost:5000',
  local_whitelabel: 'http://whitelabelh.aawz.local.com.br:5000',
  dev: 'https://dev.aawz.com.br',
  test: 'https://httpbin.org',
  prod: 'https://portal.aawz.com.br',
}

const dev = {
  espelho: '/mirror',
}

const user = {
  update_crawlers: '/v2/api/client/crawler/send_all',
}

/* TELAS */

const login = {
  /**
   *
   * @param {String} email
   */
  check: '/v2/login/pre_login',
  /**
   *
   * @param {String} login
   * @param {String} password
   * @param {String} id_escritorio
   */
  cliente: '/v2/login/login/client',
  /**
   *
   * @param {String} login
   * @param {String} password
   */
  profissional: '/v2/login/login/professional',
}

const cadastro = {
  /**
   *
   * @param {String} nome
   * @param {String} cpf
   * @param {String} telefone formatado
   * @param {String} email
   * @param {String} password
   */
  profissional: '/v2/signup/professional/register',
  /**
   *
   * @param {String} doc formatado
   * @param {String} nome
   * @param {String} email
   * @param {String} password
   * @param {Number} id_escritorio whitelabel ou 3
   */
  cliente: '/v2/signup/client/register',
  /**
   * @param {String} login
   */
  check_professional: '/v2/signup/office/get_login_data',
  /**
   * @param {String} nome responsavel
   * @param {String} cpf
   * @param {String} telefone numero formatado
   * @param {String} email
   * @param {String} password
   * @param {String} cnpj
   * @param {String} nome_empresa
   */
  empresa: '/v2/signup/office/register',
}

const recuperar = {
  client: {
    /**
     * @param {String} login
     * @param {Number} id_escritorio
     */
    senha: '/v2/login/login/client/forgot_password',
    /**
     * @param {String} login
     * @param {Number} id_escritorio
     * @param {String} validation_code
     * @param {String} new_password
     */
    codigo: '/v2/login/login/client/reset_password',
    /**
     * @param {String} email
     */
    texto_puro: '/v2/login/login/client/forgot_password_text',
  },
  professional: {
    /**
     * @param {String} login
     * @param {Number} id_escritorio
     */
    senha: '/v2/login/login/professional/forgot_password',
    /**
     * @param {String} login
     * @param {Number} id_escritorio
     * @param {String} validation_code
     * @param {String} new_password
     */
    codigo: '/v2/login/login/professional/reset_password',
    /**
     * @param {String} email
     */
    texto_puro: '/v2/login/login/professional/forgot_password_text',
  },
}

module.exports = {
  baseURL,
  dev,
  user,
  login,
  cadastro,
  recuperar,
}
