const SHOW_SNACKBAR = (state, payload) => {
  state.snackbar = { ...payload, show: true }
}

const CLOSE_SNACKBAR = (state) => {
  state.snackbar = {
    ...state.snackbar,
    show: false,
  }
}

const SHOW_MODAL = (state, payload) => {
  state.modal = { ...payload, show: true }
}

const CLOSE_MODAL = (state) => {
  state.modal = {
    ...state.modal,
    show: false,
  }
}

export { SHOW_SNACKBAR, CLOSE_SNACKBAR, SHOW_MODAL, CLOSE_MODAL }
