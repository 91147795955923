export default {
  config_local: {
    leftbar_expand: true,
    // last_page: 'carteira', // TODO: se necessario abrir na ultima pagina
  },
  classColors: {
    Multimercado: '#374459',
    Internacional: '#2e86ab',
    'Renda Variável': '#2fb89f',
    'Fundo Imobiliário': '#00796B',
    'RF Pós-Fixado': '#645e9d',
    'RF Inflação': '#e75735',
    'RF Pré-Fixado': '#f29741',
    'RF IGP-M': '#ffbb00',
    COE: '#6c757d',
    'Fundo de Previdência': '#6E1100',
    Caixa: '#6c757d',
    Alternativos: '#6c757d',
  },
  banksColors: {
    xp: '#644D40',
    bb: '#2D6727',
    itau: '#FF8F00',
    santander: '#6C757D',
    bradesco: '#65CB3B',
    btg: '#FFBB00',
    safra: '#AAABE2',
    genial: '#F29741',
  },
  colors: {
    mode: 'light_mode',
    paleta: [
      '#374459',
      '#2E86AB',
      '#6E1100',
      '#E75735',
      '#986F4B',
      '#2FB89F',
      '#645E9D',
      '#7FB97F',
      '#F6E89F',
      '#644D40',
      '#2D6727',
      '#FF8F00',
      '#6C757D',
      '#65CB3B',
      '#FFBB00',
      '#AAABE2',
      '#F29741',
    ],
    light_mode: {
      reds: [
        '#DC1313',
        '#FE7373',
        '#D05959',
        '#943A3A',
        '#551D1D',
        '#9E3A5E',
        '#D33A71',
        '#FB4687',
        '#EB5757',
        '#FF1744',
        '#FF5252',
        '#F44336',
        '#EF9A9A',
      ],
      revenues: [
        '#54AA76',
        '#38D600',
        '#0C7A7A',
        '#4269F1',
        '#B942F1',
        '#1BD4D4',
        '#664EFF',
        '#374459',
      ],
      greens: [
        '#56af79',
        '#112318',
        '#9acfaf',
        '#1f3f2b',
        '#89c7a1',
        '#2d5b3f',
        '#78bf94',
        '#3a7752',
        '#67b786',
        '#489366',
      ],
      red: '#EB5757',
      redDarker: '#D50000',
      yellow: '#FFD500',
      green: '#56AF79', // '#00E988',
      white: '#FFFFFF',
      black: '#030309',
      blue: '#3242B9',
      'green-light': '#C8E6C9',
      'red-light': '#FFCDD2',
      'yellow-light': '#FFF9C4',
      'gray-1': '#F0F1F5',
      'gray-2': '#F1EFF2',
      'gray-3': '#E4E0E6',
      'gray-4': '#C7BBC9',
      'gray-5': '#A79CAC',
      'gray-6': '#87798D',
      'gray-7': '#38303B',
      'gray-8': '#2D212F',
      'gray-chart': '#D7D7D7',
      primary: '#56AF79',
      'primary-light': '#6eba8c',
      'primary-dark': '#4fa872',
      secondary: '#8e8e8e', // '#C7BBC9',
      success: '#6FCF97', // '#00E988',
      danger: '#EB5757',
      light: '#F0F1F5',
      dark: '#2D212F',
      purple: '#7000FF',
      warning: '#FF6433', // '#FFD500',
      info: '#2196F3',
      'v-anchor-base': '#3242B9',
      'v-primary-base': '#56AF79',
    },
  },
  escritorio: {
    nome: 'AAWZ',
    id_escritorio: 3,
  },
  logo: 'https://aawz-public-tenancies.s3.us-east-2.amazonaws.com/offices/3/logo.png',
  favicon:
    'https://aawz-public-tenancies.s3.us-east-2.amazonaws.com/offices/3/favicon.png',
  backgroundImage:
    'https://aawz-public-tenancies.s3.us-east-2.amazonaws.com/offices/3/bg-login.png',
}
