<script>
export default {
  // props: ['primary', 'secondary', 'dark'],
  props: {
    primary: Boolean,
    secondary: Boolean,
    dark: Boolean,
    outline: Boolean,
    none: Boolean,
    underlined: Boolean,
    small: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customClass() {
      if (this.none) {
        return ''
      }
      let classe = 'btn btn'

      if (this.outline) {
        classe += '-outline'
      }
      if (this.primary) {
        classe += '-primary'
      }
      if (this.secondary) {
        classe += '-secondary'
      }
      if (this.dark) {
        classe += '-dark'
      }
      if (this.underlined) {
        classe += '-underlined'
      }

      if (this.small) {
        classe += ' btn-md'
      }
      return classe
    },
  },
}
</script>

<template>
  <button :class="customClass" v-on="$listeners">
    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
    <slot v-else />
  </button>
</template>

<style lang="sass" module></style>
